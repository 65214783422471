<template>
  <div class="fill-height overflow-y-auto theme-bg">
    <div class="d-flex pt-4 pb-3 px-4 align-center">
      <h2 class="primary--text font-weight-regular">ประวัติซ่อมบำรุง</h2>
      <!-- <v-select
      class="px-4 hidden-sm-and-up"
      :items="tabComponent"
      v-model="tab"
      item-text="name"
      item-value="tab"
      outlined
      dense
      hide-details
    ></v-select> -->
      <v-btn
        color="primary"
        class="ml-auto"
        @click="exportMaintenancePlanDialog = !exportMaintenancePlanDialog"
      >
        <v-icon left>mdi-export-variant</v-icon>
        ส่งออก
      </v-btn>
    </div>
    <div class="theme-bg">
      <v-tabs
        v-if="$vuetify.breakpoint.smAndUp"
        v-model="tab"
        class="px-4"
        background-color="theme-bg"
        :grow="false"
      >
        <v-tab v-for="(item, index) in tabComponent" :key="index">
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in tabComponent" :key="index">
          <component
            class="theme-bg"
            :is="item.component"
            v-if="index === tab"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <ExportMaintenancePlanDialog
      v-model="exportMaintenancePlanDialog"
      @close="exportMaintenancePlanDialog = false"
    />
  </div>
</template>

<script>
import ExportMaintenancePlanDialog from '@/components/maintenance/dialog/ExportMaintenancePlanDialog.vue'
export default {
  components: {
    ExportMaintenancePlanDialog
  },
  data: () => ({
    exportMaintenancePlanDialog: false,
    tab: 0,
    tabComponent: [
      // {
      //   name: 'ประวัติการซ่อมบำรุง',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "maintenance-history" */ '../components/maintenance/MaintenanceHistory.vue'
      //     ),
      //   tab: 0
      // },
      // {
      //   name: 'วิธีการซ่อมบำรุง',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "maintenance-method" */ '../components/maintenance/MaintenanceMethod.vue'
      //     ),
      //   tab: 1
      // }
      {
        name: 'แผนรายการ',
        component: () =>
          import(
            /* webpackChunkName: "estimate-proposal" */ '../components/maintenance/MaintenancePlanTab.vue'
          ),
        tab: 0
      }
      // {
      //   name: 'ที่ดินและสิ่งก่อสร้าง',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "maintenance-method" */ '../components/maintenance/MaintenanceLandTab.vue'
      //     ),
      //   tab: 1
      // },
      // {
      //   name: 'จ้างเหมาราคา',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "maintenance-method" */ '../components/maintenance/MaintenanceContractSummaryTab.vue'
      //     ),
      //   tab: 2
      // },
      // {
      //   name: 'จ้างเหมาซ่อมสะพาน',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "maintenance-method" */ '../components/maintenance/MaintenanceContractTab.vue'
      //     ),
      //   tab: 3
      // },
      // {
      //   name: 'จ้างเหมาวิธีตกลงราคา',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "maintenance-method" */ '../components/maintenance/MaintenanceContractPriceTab.vue'
      //     ),
      //   tab: 4
      // }
    ]
  })
}
</script>

<style></style>
