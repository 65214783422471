<template>
  <div>
    <FilterTemplateDialog
      v-model="isOpenDialog"
      title="ส่งออกข้อมูล"
      submitTitle="ดำเนินการ"
      @clear="clearForm()"
      @close="close()"
      @confirm="confirm()"
    >
      <div class="pa-4">
        <v-form
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row align="center">
            <v-col
              v-for="(form, formindex) in formFilterField"
              :key="formindex"
              cols="12"
              :sm="form.col"
            >
              <v-row align="center">
                <v-col
                  class="form-text"
                  :class="$vuetify.breakpoint.xs && 'pb-0'"
                  cols="12"
                  :sm="form.coltext"
                >
                  {{ form.name }}
                </v-col>
                <v-col
                  :class="$vuetify.breakpoint.xs && 'pt-0'"
                  cols="12"
                  :sm="form.colfield"
                >
                  <v-select
                    v-if="form.model === 'ref_maintenance_id'"
                    v-model="exportForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details="auto"
                    :no-data-text="form.nodata"
                    :items="maintenanceByGroup"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                    :rules="form.isrequired ? [rules.required] : []"
                  ></v-select>
                  <v-select
                    v-else-if="form.model === 'depot_code'"
                    v-model="exportForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details
                    :no-data-text="form.nodata"
                    :items="depotByDistrict"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                  ></v-select>
                  <v-text-field
                    v-else-if="form.type === 'text'"
                    v-model="exportForm[form.model]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-else-if="form.type === 'number'"
                    v-model="exportForm[form.model]"
                    class="right-input"
                    type="number"
                    :placeholder="form.placeholder ? form.placeholder : '0'"
                    min="0"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-select
                    v-else-if="form.model === 'section_part_id'"
                    v-model="exportForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details
                    :no-data-text="form.nodata"
                    :items="itemLists[form.select.name]"
                    :loading="sectionPartLoading"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                  ></v-select>
                  <v-select
                    v-else-if="form.type === 'select'"
                    v-model="exportForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details
                    :no-data-text="form.nodata"
                    :items="itemLists[form.select.name]"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                  ></v-select>
                  <v-autocomplete
                    v-else-if="form.model === 'bridge_id'"
                    v-model="exportForm[form.model]"
                    outlined
                    dense
                    clearable
                    placeholder="เลือก"
                    hide-details="auto"
                    :no-data-text="form.nodata"
                    :items="bridgeListByRoad"
                    :item-text="form.autocomplete['item-text']"
                    :item-value="form.autocomplete['item-value']"
                  >
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item.name }}
                        {{ item.direction ? `(${item.direction})` : '' }}
                      </div>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index === 0">
                        <span
                          >{{ item.name }}
                          {{
                            item.direction ? `(${item.direction})` : ''
                          }}</span
                        >
                      </div>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ exportForm[form.model].length - 1 }} รายการ)
                      </span>
                    </template>
                  </v-autocomplete>
                  <!-- <div v-if="form.type === 'select'">{{ form.select.name }}</div> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </FilterTemplateDialog>
  </div>
</template>

<script>
import FilterTemplateDialog from './FilterTemplateDialog.vue'
export default {
  components: {
    FilterTemplateDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.isOpenDialog = newVal
      }
    },
    'exportForm.road_id' (newVal, oldVal) {
      if (newVal) {
        delete this.exportForm.section_part_id
        this.getSectionPart(newVal)
      } else {
        delete this.exportForm.section_part_id
        this.itemLists.control = []
      }
    },
    'exportForm.ref_maintenance_group_id' (newVal, oldVal) {
      if (newVal) {
        const isMaintenanceInclude = this.maintenanceByGroup.find(
          (maintenance) => maintenance.id === this.exportForm.ref_maintenance_id
        )
        if (!isMaintenanceInclude) delete this.exportForm.ref_maintenance_id
      } else {
        delete this.exportForm.ref_maintenance_id
      }
    },
    'exportForm.depot_code' (newVal, oldVal) {
      this.getRefRoad()
    },
    'exportForm.district_code' (newVal, oldVal) {
      this.getRefRoad()
    },
    'exportForm.division' (newVal, oldVal) {
      if (newVal) {
        this.itemLists.districtByDivision = this.itemLists[
          'v-mbmms-district-exist-only'
        ].filter((district) => district.division_code === newVal)
        const checkid = this.itemLists.districtByDivision.some(
          (id) => id.distrit_code === this.exportForm.district_code
        )
        if (!checkid) {
          // delete this.exportForm.district_code
          this.exportForm.district_code = null
        }
      } else {
        this.itemLists.districtByDivision = []
        // delete this.exportForm.district_code
        this.exportForm.district_code = null
      }
    },
    depotByDistrict (newVal, oldVal) {
      const depotAvailable = newVal.find(
        (depot) => depot.depot_code === this.exportForm.depot_code
      )
      if (!depotAvailable) {
        this.exportForm.depot_code = null
      }
    },
    bridgeListByRoad (newVal, oldVal) {
      const checkid = newVal.find(
        (bridge) => bridge.id === this.exportForm.bridge_id
      )
      if (!checkid && this.exportForm.bridge_id) {
        delete this.exportForm.bridge_id
      }
    }
  },
  data: () => ({
    isOpenDialog: true,
    filter: {},
    exportForm: {},
    province: [],
    itemLists: {
      road: [],
      control: [],
      bridgeType: [],
      province: [],
      'v-mbmms-depot-exist-only': [],
      bridgeList: []
    },
    formFilterField: [
      {
        name: 'ปีงบประมาณ',
        model: 'budget_year',
        placeholder: (new Date().getFullYear() + 543).toString(),
        col: 12,
        coltext: 2,
        colfield: 4,
        type: 'number'
      },
      {
        name: 'รหัสงาน',
        model: 'ref_maintenance_group_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'maintenance_group',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'งาน',
        model: 'ref_maintenance_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        nodata: 'โปรดเลือกรหัสงาน',
        select: {
          name: 'maintenanceByGroup',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'สำนัก',
        model: 'division',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'v-mbmms-division-exist-only',
          'item-text': 'name',
          'item-value': 'division_code'
        }
      },
      {
        name: 'แขวงทางหลวง',
        model: 'district_code',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        nodata: 'โปรดเลือกหมวดทางหลวง',
        select: {
          name: 'districtByDivision',
          'item-text': 'name',
          'item-value': 'district_code'
        }
      },
      {
        name: 'หมวดทางหลวง',
        model: 'depot_code',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'select',
        select: {
          name: 'v-mbmms-depot-exist-only',
          'item-text': 'name',
          'item-value': 'depot_code'
        }
      },
      {
        name: 'หมายเลขทางหลวง',
        model: 'road_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'road',
          'item-text': 'label',
          'item-value': 'road_id'
        },
        nodata: 'ไม่มีหมายเลขทางหลวงให้เลือก'
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        nodata: 'โปรดเลือกหมายเลขทางหลวง',
        select: {
          name: 'control',
          'item-text': 'label',
          'item-value': 'section_part_id'
        }
      },
      {
        name: 'สะพาน',
        model: 'bridge_id',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'autocomplete',
        autocomplete: {
          name: 'bridgeList',
          'item-text': 'name',
          'item-value': 'id'
        },
        nodata: 'ไม่มีสะพานให้เลือก'
      }
    ],
    name: null,
    sectionPartLoading: false
  }),
  async mounted () {
    this.getRefRoad()
    this.getRef('maintenance')
    this.getRef('maintenance_group')
    this.getRef('v-mbmms-district-exist-only')
    this.getRef('v-mbmms-division-exist-only')
    this.getRef('v-mbmms-depot-exist-only')
    this.getBridgeList()
    // this.exportForm = {}
  },
  computed: {
    maintenanceByGroup () {
      if (
        this.exportForm.ref_maintenance_group_id ||
        this.exportForm.ref_maintenance_id
      ) {
        return this.itemLists.maintenance.filter(
          (maintenance) =>
            maintenance.ref_maintenance_group_id ===
            this.exportForm.ref_maintenance_group_id
        )
      } else return []
    },
    bridgeListByRoad () {
      if (this.exportForm.section_part_id) {
        return this.itemLists.bridgeList.filter(
          (bridge) =>
            bridge.section_part_id === parseInt(this.exportForm.section_part_id)
        )
      } else if (this.exportForm.road_id) {
        const road = this.itemLists.road.find(
          (road) => road.road_id === this.exportForm.road_id
        )
        return this.itemLists.bridgeList.filter(
          (bridge) => bridge.code_road === road.code
        )
      } else if (this.itemLists.bridgeList.length) {
        return this.itemLists.bridgeList
      } else return []
    },
    depotByDistrict () {
      if (this.itemLists['v-mbmms-depot-exist-only'].length) {
        if (this.exportForm.district_code) {
          return this.itemLists['v-mbmms-depot-exist-only'].filter(
            (depot) => depot.district_code === this.exportForm.district_code
          )
        } else return this.itemLists['v-mbmms-depot-exist-only']
      } else return []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      const exportFormTemp = { ...this.exportForm }
      const exportForm = {}
      exportForm.year = exportFormTemp.budget_year
        ? parseInt(exportFormTemp.budget_year)
        : 0
      exportForm.task_id = exportFormTemp.ref_maintenance_group_id || 0
      exportForm.task_name = exportFormTemp.ref_maintenance_id
        ? this.itemLists.maintenance.find(
          (maintenance) =>
            maintenance.id === exportFormTemp.ref_maintenance_id
        ).name
        : 0
      exportForm.division_code = exportFormTemp.division || 0
      exportForm.district_code = exportFormTemp.district_code || 0
      exportForm.depot_code = exportFormTemp.depot_code || 0
      exportForm.section_no = exportFormTemp.section_part_id
        ? this.itemLists.control.find(
          (control) => control.id === exportFormTemp.control
        ).code
        : 0
      exportForm.route_no = exportFormTemp.road_id
        ? this.itemLists.road.find(
          (road) => road.road_id === exportFormTemp.road_id
        ).label
        : 0
      exportForm.bridge_id = exportFormTemp.bridge_id || 0
      const params = new URLSearchParams(exportForm)
      const link = `${
        process.env.VUE_APP_REPORT
      }/Reports/M_BMMS_reports/maintenance.pdf?${params.toString()}`
      window.open(link, '_blank')
    },
    clearForm () {
      this.exportForm = {}
      this.$refs.form.reset()
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },

    async getRefRoad () {
      const filter = {
        depot_code: this.exportForm.depot_code,
        district_code: this.exportForm.district_code
      }
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only',
        filter
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = roads.some((id) => id.road_id === this.exportForm.road_id)
      if (!checkid) {
        delete this.exportForm.road_id
        delete this.exportForm.section_part_id
        this.itemLists.control = []
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      this.sectionPartLoading = true
      this.itemLists.control = []
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
      this.sectionPartLoading = false
    },
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push({
          header: `ทางหลวงหมายเลข ${parseInt(section.code_road)} - ${
            section.name
          }`,
          code_road: section.code_road,
          section_part_id: section.section_part_id
        })
        section.items.map((bridge) => {
          bridge.section_part_id = section.section_part_id
          bridge.code_road = section.code_road
        })
        this.itemLists.bridgeList.push(...section.items)
      })
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
